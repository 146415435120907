<template>
  <div  class="cms-dashboard-content  p-0 p-relative" v-if="data?.length">
    <div id="dashboard-state-account" class="table-stateAccount table-dashboard scroll" v-if="isLoading == false">
      <div class="
          table-header table-billing-header
          font_helvetica_neue75_bold
          data-header
          header-sticky
        ">
        <div class="table-header-title">
          <p>{{ $t('user.billing.dashboardStateAccount.tableHeaders.header') }}</p>
        </div>
        <div class="table-header-title xs-none">
          <p>{{ $t('user.billing.dashboardStateAccount.tableHeaders.orderNumber') }}</p>
        </div>
        <div class="table-header-title xs-none">
          <p>{{ $t('user.billing.dashboardStateAccount.tableHeaders.documentDate') }}</p>
        </div>
        <div class="table-header-title">
          <p>{{ $t('user.billing.dashboardStateAccount.tableHeaders.reference') }}</p>
        </div>
        <div class="table-header-title">
          <p>{{ $t('user.billing.dashboardStateAccount.tableHeaders.amount') }}</p>
        </div>
        <div class="table-header-title xs-none">
          <p>{{ $t('user.billing.dashboardStateAccount.tableHeaders.dispatchGuide') }}</p>
        </div>
        <div class="table-header-title"></div>
      </div>

      <div class="table-content-scroll stateAccount-billing" v-for="(element, index) in data" :key="index">
        <div class="table-info data-account" id="report_<?php echo $i ?>">
          <div class="table-info-text nro_doc">
            <p>{{ element.header_text || '--' }}</p>
          </div>
          <div class="table-info-text xs-none">
            <p>{{ element.order_number || '--' }}</p>
            <span>{{ element.document_type }}</span>
          </div>
          <div class="table-info-text xs-none">
            <span>{{ element.document_date || '--' }}</span>
          </div>
          <div class="table-info-text aumont">
            <span>{{ element.reference || '--' }}</span>
          </div>
          <div class="table-info-text">
            <p>{{ element.amount || '--' }}</p>
            <span>{{ element.document_currency }}</span>
          </div>
          <button class="btn btn-secondary btn-open md-none open-info report" @click="clickInfo(element)">
            <i class="fas fa-arrow-right"></i>
          </button>

          <div class="table-info-text xs-none">
            <p>{{ element.guide || '--' }}</p>
            <span>{{ element.guide_date || '--' }}</span>
          </div>
          <div class="table-info-text d-flex xs-none p-0">
            <button :disabled="isLoadingDocument" class="btn btn-secondary btn-download d-flex gap-0-2"
              @click="getDocument(2, getDocumentNumber(element.reference, element.document_type), element.document_type, getInfoUser.ruc, company); gtmPDF();">
              <i class="fas fa-download"></i>
              <span class="text-white" v-if="!isLoadingDocument">{{ $t('user.billing.dashboardStateAccount.downloadButtons.pdf') }}</span>
              <span v-if="isLoadingDocument" class="text-white"> ... </span>
            </button>
            <button :disabled="isLoadingDocument" class="btn btn-secondary btn-download d-flex gap-0-2"
              @click="getDocument(1, getDocumentNumber(element.reference, element.document_type), element.document_type, getInfoUser.ruc, company); gtmXML();">
              <i class="fas fa-download"></i>
              <span class="text-white" v-if="!isLoadingDocument">{{ $t('user.billing.dashboardStateAccount.downloadButtons.xml') }}</span>
              <span v-if="isLoadingDocument" class="text-white"> ... </span>
            </button>
          </div>
        </div>
      </div>
      <ModalLayout :isShow="
        isShow && messageForm.messageType !== 'success' ? isShow : false
      ">
        <ModalContainer v-if="!isLoading" :close="showOrClose" :typeModal="typeModal" :imageModal="
          messageForm.messageType === 'success'
            ? '/images/decorations/success.png'
            : '/images/decorations/sorry.png'
        " :title="
  messageForm.messageType === 'success'
    ? $t('user.billing.dashboardStateAccount.modal.successTitle')
    : $t('user.billing.dashboardStateAccount.modal.errorTitle')
" :description="messageForm.message" :type="messageForm.messageType" />
      </ModalLayout>
    </div>

    <loading-component v-else />

    <div class="d-flex justify-content-xs-center justify-content-md-end mt-1" v-if="file">
      <a :href="file" download class="btn btn-secondary btn-new decoration-none" @click="gtmDownloadAll()">
        <i class="fas fa-file-csv"></i> {{ $t('user.billing.dashboardStateAccount.downloadAllButton') }}
      </a>
    </div>
    <hr class="md-none mt-1" />
    <div class="row py-1">
      <div class="table-pagination d-flex gap-1 w-100 mt-0" v-if="billingPages.length > 1">
        <div class="pages">
          <button v-for="n in billingPages" :key="n" :class="`btn ${n == pagebillingAct ? 'page-current' : 'next'}`"
            @click="changePage(n, date)">
            <i class="fas fa-chevron-left" v-if="n < pagebillingAct"></i>
            <span v-else>{{ n }}</span>
          </button>
          <span class="suspensives" v-if="pagebillingAct < user.billing.billingReport.lastPage - 2">...</span>
          <button class="btn next" @click="changePage(user.billing.billingReport.lastPage, date); gtmNextPage();"
            v-if="pagebillingAct < user.billing.billingReport.lastPage - 1">
            {{ user.billing.billingReport.lastPage }}
          </button>
        </div>
        <div class="next">
          <button class="btn btn-secondary-2 btn-next" @click="changePage(pagebillingAct + 1, date); gtmNext();"
            v-if="pagebillingAct < user.billing.billingReport.lastPage">
            <span>{{ $t('user.billing.dashboardStateAccount.pagination.next') }}</span>
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="d-flex flex-column align-center mt-1">
    <img src="/images/decorations/empty2.png" class="w-auto mb-3" />
    <h3 class="bold text-gray-350">{{ $t('user.billing.dashboardStateAccount.emptyState.noReports') }}</h3>
  </div>
</template>
<style>
#dashboard-state-account{
    max-height: 50vh !important;
    overflow-y: auto;
}
.header-sticky{
  position: sticky;
  top: 0;
  left: 0;
}
</style>
<script>
import getDocumentNumber from '../../../shared/helpers/getDocumentNumber';
import ModalLayout from '@/layouts/modals/ModalLayout';
import ModalContainer from '@/components/ModalContainer';
import BillingContentMobile from './BillingContentMobile.vue';
import useBilling from '../../composables/Dashboard/useBilling';
import useDocument from '../../composables/Dashboard/useDocument';
import { useStore } from 'vuex';
import LoadingComponent from '../../../../components/LoadingComponent.vue';
import gaFunction from '@/utils/ga/gaFunction';
export default {
  components: {
    BillingContentMobile,
    LoadingComponent,
    ModalLayout,
    ModalContainer,
  },
  props: {
    clickInfo: {
      type: Function,
      required: true,
    },
    data: {
      type: Array < Object > [],
      required: true,
    },
    file: {
      type: String,
      required: true,
    },
    date: {
      type: Object,
    }
  },
  beforeCreate() {
    this.setBillingPages();
  },
  setup() {
    const store = useStore();
    return {
      ...useBilling(),
      getDocumentNumber,
      ...store.state,
      ...useDocument(),
    };
  },
  methods: {
    changePage(page, date) {
      if (page <= this.user.billing.billingReport.lastPage) {
        this.setBillingPage(page, date);
        return;
      }
    },
    gtmDownloadAll(){
      gaFunction('event', 'clic', { 'event_category' : 'Facturacion', 'event_label' : 'Descargar todos los reportes en CSV'});
        },
    gtmXML(){
      gaFunction('event', 'clic', { 'event_category' : 'Facturacion', 'event_label' : 'Descargar reporte xml'});
    },
    gtmPDF(){
      gaFunction('event', 'clic', { 'event_category' : 'Facturacion', 'event_label' : 'Descargar reporte PDF especifico'});
    },
    gtmNext(){
      gaFunction('event', 'clic', { 'event_category' : 'Facturacion', 'event_label' : 'Siguiente lista de reportes'});
    },
    gtmNextPage(){
      gaFunction('event', 'clic', { 'event_category' : 'Facturacion', 'event_label' : 'Paginación siguiente lista de reportes'});
    }
  },
};
</script>
