<template>
     <div class="p-fixed copy-container" :class="setShowCopyContainer()" > 
        <div class="d-inline-flex align-items-center bg-green-light copy-info  text-white" v-bind:class="{'show-copy-info':copy}">
        <i class="fas fa-check-circle mr-1"></i>
        <span>Copiado</span>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        copy:{
            type:Boolean,
            required:true,
        },
        setShowCopyContainer:{
            type:Function,
            required:true
        }
    },
}
</script>