import { ref } from 'vue';
import { useStore } from 'vuex';
import getDate from '@/utils/dates/getDate';
import isNumber from '@/utils/validations/isNumber';

const useEmail = () => {
  const store = useStore();
  const isLoading = ref(false);

  const messageForm = ref({
    message: '',
    messageType: 'success',
  });

  const date = ref('');

  const showMessage = (message, messageType) => {
    isLoading.value = false;
    messageForm.value.message = message;
    messageForm.value.messageType = messageType;
  };

  const sendEmail_ = async (type) => {
    isLoading.value = true;

    if (type === 'Adeudo') {
      const { success, message } = await store.dispatch('user/getCanceledDebt');
      isLoading.value = false;
      const status = success ? 'success' : 'warning';
      showMessage(message, status);
    } else if (type === 'Saldo') {
      const { success, message } = await store.dispatch(
        'user/getUserAndAnalistData'
      );
      isLoading.value = false;
      const status = success ? 'success' : 'warning';
      showMessage(message, status);
    } else if (type === 'Vendedor') {
      const { success, message } = await store.dispatch('user/callSeller');
      isLoading.value = false;
      const status = success ? 'success' : 'warning';
      showMessage(message, status);
    }
  };

  const sendProtest = async (form) => {
    isLoading.value = true;

    const formData = new FormData(form);
    const file = formData.get('file');
    const amount = formData.get('amount');
    const invoice_number = formData.get('invoice_number');

    if (!isNumber(amount)) {
      isLoading.value = false;
      showMessage('El campo de importe debe ser numérico', 'error');
      return;
    } /*else if (!isNumber(invoice_number)) {
      isLoading.value = false;
      showMessage('Por favor verifique el campo de factura/letra, el formato no es el correcto debe ser  ej: F001-005', 'error');
      return;
    }*/else if (file.name === '') {
      isLoading.value = false;
      showMessage(
        'Por favor adjunte un archivo o documento de protesto',
        'error'
      );
      return;
    }

    if(formData.get('date') === ''){
      isLoading.value = false;
      showMessage('Por favor, agrega una fecha', 'error');
      return;
    }
    if(formData.get('invoice_number') === ''){
      isLoading.value = false;
      showMessage('Por favor, llene el campo de factura/letra', 'error');
      return;
    }
    const date = getDate(new Date(formData.get('date')));
    formData.append('date', date);

    const { success, message } = await store.dispatch(
      'user/getuprisingOfProtest',
      { formData }
    );
    isLoading.value = false;

    const status = success ? 'success' : 'error';
    showMessage(message, status);
  };

  return {
    sendEmail_,
    isLoading,
    showMessage,
    messageForm,
    sendProtest,
    date,
  };
};

export default useEmail;
