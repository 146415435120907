<template>
    <div class="account-state scroll mb-3 font_helvetica_neue55_roman md-none">
        <div class="account-info-header text-end m-1">
        <div class="close-info-report pointer d-flex report align-items-center">
            <img
            class="pointer mx-1"
            src="/images/decorations/back.png"
            alt="back"
            title="back"
            />
            <p class="font_helvetica_neue75_bold text-back">
            Regresar a Facturas del mes
            </p>
        </div>
        </div>
        <div class="account-info-body mt-3 mx-3 mb-3">
        <div class="header_info">
            <h3 class="mt-1 mb-1 font_helvetica_neue75_bold text-primary">
            Cabecera:
            </h3>
            <h3 class="mt-1 mb-2 font_helvetica_neue55_roman">{{data.header}}</h3>
        </div>

        <div class="request">
            <h3 class="mt-1 mb-1 font_helvetica_neue75_bold text-primary">
            Nº pedido:
            </h3>
            <h3 class="mt-1 mb-2 font_helvetica_neue55_roman">{{data.request}}</h3>
        </div>

        <div class="document">
            <h3
            class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            "
            >
            Tipo de documento en SUNAT
            </h3>
            <h3 class="mt-1 mb-2">{{typeDocument}}</h3>
        </div>
        <div class="document-date">
            <h3
            class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            "
            >
            Fecha de documento
            </h3>
            <h3 class="mt-1 mb-2">
            <h3 class="font_helvetica_neue55_roman"></h3>
            </h3>
        </div>
        <div class="document-date-expire d-none">
            <h3
            class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            "
            >
            Fecha de vencimiento
            </h3>
            <h3 class="mt-1 mb-2 d-flex justify-between align-items-center">
            <h3 class="font_helvetica_neue55_roman"></h3>
            </h3>
        </div>

        <div class="reference">
            <h3
            class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            "
            >
            Referencia
            </h3>
            <h3 class="mt-1 mb-2">
            <h3 class="font_helvetica_neue55_roman"></h3>
            </h3>
        </div>
        <div class="amount">
            <h3
            class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            "
            >
            Importe
            </h3>
            <h3 class="mt-1 mb-2navb d-flex flex-column">
            <span class="font_helvetica_neue55_roman"> </span>
            <p class="text-gray-150"></p>
            </h3>
        </div>

        <div class="gui">
            <h3
            class="
                mt-1
                mb-1
                font_helvetica_neue75_bold
                text-primary
                d-flex
                justify-between
                align-items-center
            "
            >
            Guía de remisión
            </h3>
            <h3 class="mt-1 mb-2 d-flex flex-column">
            <span class="font_helvetica_neue55_roman"></span>
            <span class="font_helvetica_neue55_roman"></span>
            </h3>
        </div>
        <div class="d-flex gap-1">
            <button
            class="
                btn btn-secondary
                p-0
                w-50
                p-1
                d-flex
                align-items-center
                justify-center
            "
            >
            <span class="mr-1">PDF</span>
            <i class="fas fa-download"></i>
            </button>
            <button
            class="
                btn btn-secondary
                p-0
                w-50
                p-1
                d-flex
                align-items-center
                justify-center
            "
            >
            <span class="mr-1">XML</span>
            <i class="fas fa-download"></i>
            </button>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        data:{
        type:Object,
        },
        clickCopy:{
        type:Function,
        required:true,
        }
    }
}
</script>