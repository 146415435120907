import { ref } from 'vue';
import isValidEmail from '@/utils/validations/isValidEmail';
import isEmpty from '@/utils/validations/isEmpty';

import { useStore } from 'vuex';

const useSelectProfile = () => {
  const store = useStore();

  let selectState = ref('view');
  let setForm = ref(true);

  const isLoading = ref(false);
  const userFormUpdate = ref({
    email: store.state.user.userData.email ?? '',
    cellphone: store.state.user.userData.cellphone ?? '',
    address: store.state.user.userData.address ?? '',
  });

  const messageForm = ref({
    message: '',
    messageType: '',
  });

  const resetValues = () => {
    messageForm.value.message = '';
    messageForm.value.messageType = '';
  };

  let setSelectState = (state, action = '') => {
    selectState.value = state;
    if (state === 'edit') {
      setForm.value = false;
    } else {
      if (action === 'update') {
        userUpdateForm();
      } else {
        resetValues();
      }
      setForm.value = true;
    }
  };

  const userUpdateForm = async () => {
    isLoading.value = true;
    resetValues();
    if (!isValidEmail(userFormUpdate.value.email)) {
      isLoading.value = false;
      messageForm.value.message = 'Por favor, ingrese un correo válido';
      messageForm.value.messageType = 'error';
      return;
    }
    if(userFormUpdate.value.cellphone.length &&
      userFormUpdate.value.cellphone.length != 9 ){
      isLoading.value = false;
      messageForm.value.message = 'Por favor, ingrese un número de celular válido';
      messageForm.value.messageType = 'error';
      return;
    }

    const { success, message } = await store.dispatch('user/userUpdate', {
      ...userFormUpdate.value,
    });

    isLoading.value = false;
    const status = success ? 'success' : 'error';
    messageForm.value.message = message;
    messageForm.value.messageType = status;
    let user = JSON.parse(localStorage.getItem('x-user'));
    user.email = userFormUpdate.value.email;
    user.cellphone = userFormUpdate.value.cellphone;
    user.address = userFormUpdate.value.address;

    localStorage.setItem('x-user',JSON.stringify(user));

    setTimeout(() => {
      resetValues();
    }, 2500);
  };

  return {
    resetValues,
    isLoading,
    messageForm,
    setSelectState,
    selectState,
    setForm,
    userFormUpdate,
  };
};

export default useSelectProfile;
