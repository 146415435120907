<template>
    <div class="" id="dasboard-billing-report" v-if="data?.length">
        <!-- <?php include 'dashboard-content-billing-state.php' ?> -->
        <div class="table-billing-header table-billing-header scroll" id="dashboard-state-account" v-if="isLoading == false">
            <div class="table-header table-billing-header header-sticky">
                <div class="table-header-title">
                    <p>{{ $t('user.billing.dashboardBillingReport.tableHeaders.documentNumber') }}</p>
                </div>
                <div class="table-header-title xs-none">
                    <p>{{ $t('user.billing.dashboardBillingReport.tableHeaders.documentDate') }}</p>
                </div>
                <div class="table-header-title">
                    <p>{{ $t('user.billing.dashboardBillingReport.tableHeaders.dueDate') }}</p>
                </div>
                <div class="table-header-title">
                    <p>{{ $t('user.billing.dashboardBillingReport.tableHeaders.amount') }}</p>
                </div>
                <div class="table-header-title xs-none">
                    <p>{{ $t('user.billing.dashboardBillingReport.tableHeaders.uniqueNumber') }}</p>
                </div>
                <div class="table-header-title xs-none">
                    <p>{{ $t('user.billing.dashboardBillingReport.tableHeaders.bank') }}</p>
                </div>
                <div class="table-header-title"></div>
                <div class="table-header-title xs-none">
                    <p>{{ $t('user.billing.dashboardBillingReport.tableHeaders.downloadDate') }}</p>
                </div>
            </div>
    
            <div class="table-content-scroll" :key="index" v-for="(element, index) in data">
                <div class="table-info table-billing-info state-account">
                    <div class="table-info-text nro_doc">
                        <p>{{ element.document_number || '--' }}</p>
                        <p class="d-none d-lg-block" :class="element.document_type === letterPending ? 'pending' : element.document_type === letterAcepted ? 'accepted' : ''">
                            {{ element.document_type }}
                        </p>
                        <p class="d-block d-lg-none" :class="element.document_type === letterPending ? 'pending' : element.document_type === letterAcepted ? 'accepted' : ''">
                            {{ element.document_type === letterPending ? $t('user.billing.dashboardBillingReport.modal.pendingTitle') : element.document_type }}
                        </p>
                    </div>
                    <div class="table-info-text xs-none">
                        <p>{{ element.document_date || '--' }}</p>
                    </div>
                    <div class="table-info-text">
                        <p>{{ element.document_due_date || '--' }}</p>
                    </div>
                    <div class="table-info-text aumont">
                        <p>{{ element.amount || '--' }}</p>
                        <p>{{ element.currency }}</p>
                    </div>
                    <div class="table-info-text xs-none">
                        <p>{{ element.number || '--' }}</p>
                    </div>
    
                    <div class="table-info-text xs-none">
                        <p>{{ element.bank || '--' }}</p>
                    </div>
    
                    <div class="table-info-text d-flex justify-center xs-none">
                        <button :disabled="element.isLoadingPDF" v-if="element.document_type === letterPending" @click="getDocument(element.id);gtmPDF();" class="btn-open btn-other btn-secondary btn-download d-flex gap-0-2 justify-center">
                            <i class="fas fa-download"></i>
                            <p class="text-white xs-none" v-if="!element.isLoadingPDF">{{ $t('user.billing.dashboardBillingReport.downloadButtons.pdf') }}</p>
                            <p v-if="element.isLoadingPDF" class="text-white"> ... </p>
                        </button>
                    </div>
                    <div class="table-info-text xs-none">
                        <p>{{ (element.date_download ? getFormatDate(element.date_download) : '--') ?? '--' }}</p>
                    </div>
                    <div class="table-info-text d-flex justify-center">
                        <button class="btn btn-secondary btn-open open-info status" @click="clickInfo(element)">
                            <i class="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <loading-component v-else />
    
        <div class="d-flex justify-between mt-2">
            <div class="table-pagination d-flex gap-1 mt-2" v-if="accountPages.length > 1">
                <div class="pages">
                    <button v-for="n in accountPages" :key="n" :class="`btn ${n == pageAccountAct ? 'page-current' : 'next'}`" @click="changePage(n, date)">
                        <i class="fas fa-chevron-left" v-if="n < pageAccountAct"></i>
                        <p v-else>{{ n }}</p>
                    </button>
                    <p class="suspensives" v-if="pageAccountAct < user.billing.accountState.lastPage - 2">...</p>
                    <button class="btn next" @click="changePage(user.billing.accountState.lastPage, date)" v-if="pageAccountAct < user.billing.accountState.lastPage - 1">
                        {{ user.billing.accountState.lastPage }}
                    </button>
                </div>
                <button class="btn btn-secondary-2 btn-next" style="height: 52px;" v-if="pageAccountAct < user.billing.accountState.lastPage" @click="changePage(pageAccountAct + 1, date)">
                    <p>{{ $t('user.billing.dashboardBillingReport.pagination.next') }}</p>
                    <i class="fas fa-chevron-right"></i>
                </button>
            </div>
            <div class="d-flex flex-column text-primary" style="align-items: end;">
                <div class="d-block text-center">
                    <h5>{{ $t('user.billing.dashboardBillingReport.downloadAllButton') }}</h5>
                    <div class="d-flex justify-content-end mt-1 gap-1" v-if="file" @click="gtmDownloadExcel()">
                        <a :href="fileExcel" download class="d-flex gap-1 align-items-center btn btn-secondary btn-new decoration-none">
                            <i class="fas fa-file-pdf"></i> {{ $t('user.billing.dashboardBillingReport.downloadButtons.excel') }}
                        </a>
                        <a :href="file" download class="d-flex gap-1 align-items-center btn btn-secondary btn-new decoration-none"  @click="gtmDownloadPDF()">
                            <i class="fas fa-file-pdf"></i> {{ $t('user.billing.dashboardBillingReport.downloadButtons.pdf') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="d-flex flex-column align-center mt-1">
        <img src="/images/decorations/empty2.png" class="w-auto mb-3" />
        <h3 class="bold text-gray-350">{{ $t('user.billing.dashboardBillingReport.emptyState.noReports') }}</h3>
        <ModalLayout :isShow="isShow">
            <ModalContainer :close="showOrClose" :typeModal="typeModal" :imageModal="messageForm.messageType === 'success' ? '/images/decorations/success.png' : '/images/decorations/sorry.png'" :title="messageForm.messageType === 'success' ? $t('user.billing.dashboardBillingReport.modal.successTitle') : $t('user.billing.dashboardBillingReport.modal.errorTitle')" :description="messageForm.message" :type="messageForm.messageType" />
        </ModalLayout>
    </div>
    </template>

<style>
#dashboard-state-account{
    max-height: 50vh !important;
    overflow-y: auto;
}
.header-sticky{
  position: sticky;
  top: 0;
  left: 0;
}
</style>

<script>
import useBilling from '../../composables/Dashboard/useBilling';
import {
    useStore
} from 'vuex';
import LoadingComponent from '../../../../components/LoadingComponent.vue';
import ModalLayout from '../../../../layouts/modals/ModalLayout';
import ModalContainer from '../../../../components/ModalContainer';
import getFormatDate from '../../../../helpers/getFormatDate';
import gaFunction from '@/utils/ga/gaFunction';
export default {
    components: {
        LoadingComponent,
        ModalContainer,
        ModalLayout
    },
    props: {
        data: {
            type: Array < Object > [],
            required: true,
        },
        clickInfo: {
            type: Function,
            required: true,
        },
        file: {
            type: String,
            required: true,
        },
        date: {
            type: Object,
        },
        fileExcel: {
            type: String,
            required: true,
        },
    },
    beforeCreate() {
        this.setAccountPages();
    },
    setup() {
        const store = useStore();

        return {
            ...useBilling(),
            ...store.state,
            getFormatDate
        };
    },
    methods: {
        changePage(page, date) {
            if (page <= this.user.billing.accountState.lastPage) {
                this.setAccountPage(page, date);
                return;
            }
        },
        gtmDownloadExcel(){
            gaFunction('event', 'clic', { 'event_category' : 'Facturacion', 'event_label' : 'Descargar todo en Excel'});
        },
        gtmDownloadPDF(){
            gaFunction('event', 'clic', { 'event_category' : 'Facturacion', 'event_label' : 'Descargar todo en PDF'});
        },
        gtmPDF(){
            gaFunction('event', 'clic', { 'event_category' : 'Facturacion', 'event_label' : 'Descargar PDF especifico'});
        }
    },
};
</script>
