<template>
  <div class="form-wrapper">
    <div class="form form-col-1 mt-2 mb-2">
      <div class="form-content text-center">
        <div class="header-images">
          <div class="logo">
            <img
              src="/images/logos/logo-faber.png"
              alt="Faber Castell"
              title="Faber Castell"
            />
          </div>
          <div class="featured">
            <img
              src="/images/decorations/email-confirmated.png"
              alt="Email éxitoso"
              title="Email éxitoso"
            />
          </div>
        </div>
        <div class="body">
          <h1 class="font_helvetica_neue75_bold">
            ¡Te damos la bienvenida a Credifaber!
          </h1>
          <h4 class="font_helvetica_neue75_bold pt-1 mb-2 font-s-h3">
            Créditos y Cobranzas de Faber Castell.
          </h4>
        </div>
        <div class="buttons mt-1 mb-1">
          <button
            @click="$router.push({ path: '/usuario' })"
            class="btn-secondary font_helvetica_neue75_bold w-auto"
          >
            Empezar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
