<template>
  <DashboardLayout>
    <section class="cms-dashboard-content cms-dashboard-content-profile scroll">
      <!-- <HeaderUser /> -->
      <div class="body vh-50">
        <div class="main-title text-primary">
          <h1 class="title title-w-description font_helvetica_neue75_bold">
            {{ $t('user.cobranzas.mainTitle.title') }}
          </h1>
          <p>{{ $t('user.cobranzas.mainTitle.description') }}</p>
        </div>
        <div class="main-content mt-3">
          <div class="mt-1">
            <input
              type="radio"
              class="custom-radio"
              name="cobranza"
              id="noadeudo"
            />
            <label for="noadeudo">
              <img
                src="/images/decorations/check-inactive.png"
                alt="check"
                class="inactive"
              />
              <img
                src="/images/decorations/check.png"
                alt="check"
                class="active"
              />
              <span class="text">{{ $t('user.cobranzas.options[0].text') }}</span>
            </label>
            <div class="my-2 user.cobranzas-noadeudo p-3">
              <h3 class="mb-2 font_helvetica_neue75_bold text-account">
                {{ $t('user.cobranzas.options[0].confirmationTitle') }}
              </h3>
              <div
                class="row gap-1-5 m-none mx-md-5 user.cobranzas-options"
                v-if="!isLoading"
              >
                <button
                  class="btn btn-secondary col-12 col-md-6"
                  @click="show('Adeudo')"
                >
                  {{ $t('user.cobranzas.options[0].options[0].buttonText') }}
                </button>
                <button class="btn btn-gold col-12 col-md-6" @click="cancel">
                  {{ $t('user.cobranzas.options[0].options[1].buttonText') }}
                </button>
              </div>
              <LoadingComponent v-if="isLoading" />
            </div>
          </div>
          <div class="mt-1">
            <input
              type="radio"
              class="custom-radio"
              name="cobranza"
              id="protesto"
            />
            <label for="protesto">
              <img
                src="/images/decorations/check-inactive.png"
                alt="check"
                class="inactive"
              />
              <img
                src="/images/decorations/check.png"
                alt="check"
                class="active"
              />
              <span class="text">{{ $t('user.cobranzas.options[1].text') }}</span>
            </label>
            <div class="my-2 user.cobranzas-noadeudo p-2 p-md-4">
              <h3 class="mb-2 font_helvetica_neue75_bold text-account">
                {{ $t('user.cobranzas.options[1].confirmationTitle') }}
              </h3>
              <form class="text-left" @submit.prevent="sendProtest_" ref="form">
                <div class="row gap-2">
                  <div class="form-control mt-1 col-12">
                    <label for="company_name">{{ $t('user.cobranzas.options[1].formFields[0].label') }}</label>
                    <input
                      type="text"
                      name="amount"
                      :placeholder="$t('user.cobranzas.options[1].formFields[0].placeholder')"
                      required
                    />
                  </div>
                  <div class="form-control mt-1 col-12">
                    <label for="company_name">{{ $t('user.cobranzas.options[1].formFields[1].label') }}</label>
                    <input
                      type="text"
                      name="invoice_number"
                      :placeholder="$t('user.cobranzas.options[1].formFields[1].placeholder')"
                      required
                    />
                  </div>
                </div>
                <div class="row gap-2">
                  <div class="form-control mt-1 col-12">
                    <label for="company_name">{{ $t('user.cobranzas.options[1].formFields[2].label') }}</label>
                    <Datepicker
                      v-model="date"
                      locale="es"
                      menuClassName="dp-protesto"
                      name="date"
                      cancelText="{{ $t('common.cancel') }}"
                      selectText="{{ $t('common.apply') }}"
                      :textInputOptions="textInputOptions"
                      :placeholder="$t('user.cobranzas.options[1].formFields[2].placeholder')"
                    />
                    <!-- Esto oculta las opciones de aceptar y cancelar
                      :closeOnAutoApply="false"
                      autoApply 
                    --->
                    <input
                      type="hidden"
                      name="date"
                      :value="date"
                      :placeholder="$t('user.cobranzas.options[1].formFields[2].placeholder')"
                      required
                    />
                  </div>
                  <div class="form-control mt-1 col-12">
                    <label for="company_name">{{ $t('user.cobranzas.options[1].formFields[3].label') }}</label>
                    <input
                      type="text"
                      name="bank"
                      :placeholder="$t('user.cobranzas.options[1].formFields[3].placeholder')"
                      required
                    />
                  </div>
                </div>

                <div class="row gap-2">
                  <div class="form-control mt-1 col-12 col-md-6 mx-none">
                    <input
                      id="file"
                      type="file"
                      name="file"
                      class="d-none"
                      @change="setFile"
                      accept="image/*"
                    />
                    <label for="company_name">{{ $t('user.cobranzas.options[1].formFields[4].label') }}</label>
                    <label
                      for="file"
                      class="btn input-border text-gray-500 file-input"
                    >
                      <span
                        :class="
                          file ? 'fas fa-image' : 'fas fa-cloud-upload-alt'
                        "
                      ></span>
                      {{ file ? file.name : $t('common.uploadFile') }}
                    </label>
                  </div>
                </div>
                <div class="row gap-2">
                  <div class="form-control mt-1 col-12">
                    <label for="company_name">{{ $t('user.cobranzas.options[1].formFields[5].label') }}</label>
                    <textarea
                      type="text"
                      name="report"
                      :placeholder="$t('user.cobranzas.options[1].formFields[5].placeholder')"
                      required
                      rows="5"
                    />
                  </div>
                </div>
                <LoadingComponent v-if="isLoading" />
                <div class="row gap-1-5 m-none mx-md-5 user.cobranzas-options mt-2" v-else>
                  <button
                    class="btn btn-secondary col-12 col-md-6"
                    type="submit"
                  >
                    {{ $t('user.cobranzas.options[1].submitButtonText') }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-gold col-12 col-md-6"
                    @click="cancel"
                  >
                    {{ $t('user.cobranzas.options[1].cancelButtonText') }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ModalLayout :isShow="isShow">
      <ModalContainer
        v-if="!isLoading"
        :close="showOrClose"
        :typeModal="typeModal"
        :imageModal="messageForm.messageType === 'success' ? '/images/decorations/success.png' : '/images/decorations/sorry.png'"
        :title="messageForm.messageType === 'success' ? $t('user.cobranzas.common.congratulations') : $t('user.cobranzas.common.sorry')"
        :description="messageForm.message"
        :type="messageForm.messageType"
      />
    </ModalLayout>
  </DashboardLayout>
</template>

<style lang="scss">
@import '@/modules/user/styles/pages/dashboard-collection.scss'; //
@import '@/modules/user/styles/style.scss';
</style>
<script>
import { ref } from 'vue';
import DashboardLayout from '@/modules/shared/layouts/DashboardLayout';
import Email from '../email-confirmated/EmailConfirmatedView.vue';
import ModalLayout from '@/layouts/modals/ModalLayout';
import ModalContainer from '@/components/ModalContainer';
import useModal from '@/composables/useModal';
import ModalTest from '@/modules/auth/views/login/modals/LoginErrorModal';
import LoadingComponent from '@/components/LoadingComponent.vue';
import HeaderUser from '@/modules/user/components/HeaderUser';
import useEmail from '@/modules/user/composables/Dashboard/useEmail';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import useFile from '@/modules/admin/composables/useFile';
import gaFunction from '@/utils/ga/gaFunction';

export default {
  components: {
    DashboardLayout,
    Email,
    ModalLayout,
    ModalContainer,
    ModalTest,
    LoadingComponent,
    HeaderUser,
    Datepicker,
  },
  setup() {
    const { file, setFile } = useFile();
    const form = ref('');
    return {
      ...useModal(),
      ...useEmail(),
      file,
      setFile,
      form,
    };
  },
  methods: {
    async show(type) {
      await this.sendEmail_(type);
      this.isShow = !this.isShow;
      this.gtmColletions1()
    },
    async sendProtest_(e) {
      await this.sendProtest(e.target);
      this.isShow = !this.isShow;
      this.gtmColletions2();
    },
    gtmColletions1(){
      gaFunction('event', 'clic', { 'event_category' : 'user.Cobranzas', 'event_label' : 'Solicitar carta de no adeudo'});
    },
    gtmColletions2(){
      gaFunction('event', 'clic', { 'event_category' : 'user.Cobranzas', 'event_label' : 'Solicitar levantamiento de protesto'});
    },
    gtmCancel(){
      gaFunction('event', 'clic', { 'event_category' : 'user.Cobranzas', 'event_label' : 'Cancelar solicitud'});
    },
    cancel() {
      document.querySelector('input[type="radio"]:checked').checked = false;
      this.isShow = false;
      this.form.reset();
      this.gtmCancel()
    },
  },
};
</script>
