<template>
  <div>
    <div class="account-info-body mt-3 mx-3 mb-3" v-if="!data.hasOwnProperty('header_text')">
      <div class="header_state">
        <h3 class="mt-1 mb-1 font_helvetica_neue75_bold text-primary">
          Tipo de documento:
        </h3>
        <h3 class="mt-1 mb-2 font_helvetica_neue55_roman">
          {{ data.document_type }}
        </h3>
      </div>

      <div class="request_state">
        <h3 class="mt-1 mb-1 font_helvetica_neue75_bold text-primary">
          Nº documento:
        </h3>
        <h3 class="mt-1 mb-2 font_helvetica_neue55_roman">
          {{ data.document_number }}
        </h3>
      </div>

      <div class="date-state">
        <h3 class="
            mt-1
            mb-1
            font_helvetica_neue75_bold
            text-primary
            d-flex
            justify-between
            align-items-center
          ">
          Fecha de documento
        </h3>
        <h3 class="mt-1 mb-2 d-flex justify-between align-items-center">
          <p class="font_helvetica_neue55_roman">{{ data.document_date }}</p>
        </h3>
      </div>
      <div class="date-expire-state">
        <h3 class="
            mt-1
            mb-1
            font_helvetica_neue75_bold
            text-primary
            d-flex
            justify-between
            align-items-center
          ">
          Fecha de vencimiento
        </h3>
        <h3 class="mt-1 mb-2 d-flex justify-between align-items-center">
          <p class="font_helvetica_neue55_roman">
            {{ data.document_due_date }}
          </p>
        </h3>
      </div>
      <div class="amount-state">
        <h3 class="
            mt-1
            mb-1
            font_helvetica_neue75_bold
            text-primary
            d-flex
            justify-between
            align-items-center
          ">
          Importe
        </h3>
        <h3 class="mt-1 mb-2">
          <p class="font_helvetica_neue55_roman">{{ data.amount }}</p>
          <p class="font_helvetica_neue55_roman text-gray-150">
            {{ data.currency }}
          </p>
        </h3>
      </div>
      <div class="unique-state">
        <h3 class="
            mt-1
            mb-1
            font_helvetica_neue75_bold
            text-primary
            d-flex
            justify-between
            align-items-center
          ">
          N° único
        </h3>
        <h3 class="mt-1 mb-2">
          <p class="font_helvetica_neue55_roman">{{ data.number }}</p>
        </h3>
      </div>
      <div class="bank-state">
        <h3 class="
            mt-1
            mb-1
            font_helvetica_neue75_bold
            text-primary
            d-flex
            justify-between
            align-items-center
          ">
          Banco
        </h3>
        <h3 class="mt-1 mb-2">
          <p class="font_helvetica_neue55_roman">{{ data.bank }}</p>
        </h3>
      </div>
    </div>
    <div class="account-info-body mt-3 mx-3 mb-3" v-if="data.hasOwnProperty('header_text')">
      <div class="header_info">
        <h3 class="mt-1 mb-1 font_helvetica_neue75_bold text-primary">
          Cabecera:
        </h3>
        <h3 class="mt-1 mb-2 font_helvetica_neue55_roman">
          {{ data.header_text }}
        </h3>
      </div>

      <div class="request">
        <h3 class="mt-1 mb-1 font_helvetica_neue75_bold text-primary">
          Nº pedido:
        </h3>
        <h3 class="mt-1 mb-2 font_helvetica_neue55_roman">
          {{ data.order_number }}
        </h3>
      </div>

      <div class="document">
        <h3 class="
            mt-1
            mb-1
            font_helvetica_neue75_bold
            text-primary
            d-flex
            justify-between
            align-items-center
          ">
          Tipo de documento en SUNAT
        </h3>
        <h3 class="mt-1 mb-2">
          {{ data.document_type }}
        </h3>
      </div>
      <div class="document-date">
        <h3 class="
            mt-1
            mb-1
            font_helvetica_neue75_bold
            text-primary
            d-flex
            justify-between
            align-items-center
          ">
          Fecha de documento
        </h3>
        <h3 class="mt-1 mb-2">
          <h3 class="font_helvetica_neue55_roman">{{ data.document_date }}</h3>
        </h3>
      </div>

      <div class="reference">
        <h3 class="
            mt-1
            mb-1
            font_helvetica_neue75_bold
            text-primary
            d-flex
            justify-between
            align-items-center
          ">
          Referencia
        </h3>
        <h3 class="mt-1 mb-2">
          <h3 class="font_helvetica_neue55_roman">{{ data.reference }}</h3>
        </h3>
      </div>
      <div class="amount">
        <h3 class="
            mt-1
            mb-1
            font_helvetica_neue75_bold
            text-primary
            d-flex
            justify-between
            align-items-center
          ">
          Importe
        </h3>
        <h3 class="mt-1 mb-2navb d-flex flex-column">
          <span class="font_helvetica_neue55_roman">{{ data.amount }}</span>
          <p class="text-gray-150">{{ data.currency }}</p>
        </h3>
      </div>

      <div class="gui">
        <h3 class="
            mt-1
            mb-1
            font_helvetica_neue75_bold
            text-primary
            d-flex
            justify-between
            align-items-center
          ">
          Guía de remisión
        </h3>
        <h3 class="mt-1 mb-2 d-flex flex-column">
          <span class="font_helvetica_neue55_roman">{{ data.guide }}</span>
          <span class="font_helvetica_neue55_roman">{{ data.guide_date }}</span>
        </h3>
      </div>
      <div class="d-flex gap-1">
        <button class="
            btn btn-secondary
            p-0
            w-50
            p-1
            d-flex
            align-items-center
            justify-center
          " @click="getDocument(2, data.reference.substr(3), data.document_type)">
          <span class="mr-1 text-white" v-if="!isLoadingDocument">PDF</span>
          <span class="mr-1 text-white" v-if="isLoadingDocument">...</span>
          <i class="fas fa-download"></i>
        </button>
        <button class="
            btn btn-secondary
            p-0
            w-50
            p-1
            d-flex
            align-items-center
            justify-center
          " @click="getDocument(1, data.reference.substr(3), data.document_type)">
          <span class="mr-1 text-white" v-if="!isLoadingDocument">XML</span>
          <span class="mr-1 text-white" v-if="isLoadingDocument">...</span>
          <i class="fas fa-download"></i>
        </button>
      </div>
    </div>
    <div class="account-info-body mt-3 mx-3 mb-3" v-else>
      <button :disabled="data.isLoadingPDF" v-if="data.document_type === letterPending"
        @click="getDocument(data.id)"
        class=" btn btn-secondary
            p-0
            w-50
            p-1
            d-flex
            gap-1
            align-items-center
            justify-center">
        <i class="fas fa-download"></i>
        <p class="text-white" v-if="!data.isLoadingPDF"> PDF </p>
        <p v-if="data.isLoadingPDF" class="text-white"> ... </p>
      </button>
    </div>
    <ModalLayout :isShow="isShow">
      <ModalContainer v-if="!isLoading" :close="showOrClose" :typeModal="typeModal" :imageModal="
        messageForm.messageType === 'success'
          ? '/images/decorations/success.png'
          : '/images/decorations/sorry.png'
      " :title="
  messageForm.messageType === 'success' ? '¡Enhorabuena!' : 'Lo sentimos'
" :description="messageForm.message" :type="messageForm.messageType" />
    </ModalLayout>
  </div>
</template>

<script>
import ModalLayout from '@/layouts/modals/ModalLayout';
import ModalContainer from '@/components/ModalContainer';
import useDocument from '../../composables/Dashboard/useDocument';
import useBilling from '../../composables/Dashboard/useBilling';
export default {
  components: {
    ModalContainer,
    ModalLayout,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { letterPending , getDocument } = useBilling()
    return {
      ...useDocument(),
      getDocument,
      letterPending
    };
  },
};
</script>
