<template>
  <DashboardLayout>
    <section class="cms-dashboard-content cms-dashboard-content-profile scroll">
      <!-- <HeaderUser /> -->
      <div class="body">
        <div class="main-title text-center">
          <h1 class="title title-w-description font_helvetica_neue75_bold text-primary">
            {{user.userData.company_name}}
          </h1>
          <p class="font_helvetica_neue55_roman text-primary"> {{user.userData.security_code !== null ? $t('user.account.textSecondary') : $t('user.account.textTertiary') }} {{ user.userData.security_code !== null ? user.userData.security_code : user.userData.ruc }}</p>
        </div>
        <div class="main-content mt-3">
          <FormProfile />
        </div>
      </div>
    </section>
  </DashboardLayout>
</template>
<script>
import DashboardLayout from '@/modules/shared/layouts/DashboardLayout';
import HeaderUser from '@/modules/user/components/HeaderUser';
import useShowData from "@/modules/user/composables/Dashboard/useShowData";
import FormProfile from '@/modules/user/views/dashboard-profile/forms/FormProfile';
import { useStore } from 'vuex';

export default {
  components:{
    DashboardLayout,
    HeaderUser,
    FormProfile
  },
  setup(){
    const store = useStore();

    return{
      ...useShowData(),
      ...store.state,
    }
  }
}
</script>