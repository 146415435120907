import { ref } from 'vue';
const useCopy = () => {
   
  const copy = ref(false);
  const copy_container = ref('d-none')
  const aux = document.createElement("input");

  const setCopy = (data) =>{
    copy.value = !copy.value;
    aux.setAttribute("value",data);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    setTimeout(() =>{
      copy.value = !copy.value
    },1500)
  }

  const setShowCopyContainer = () =>{
    setInterval(() =>{
      if(window.innerWidth > 900){
        !copy ? copy_container.value = 'd-none' : copy_container.value = ''
      }else{
        copy_container.value = ''
      }
    },100)
    return copy_container.value
  }

  return {
    copy,
    setCopy,
    setShowCopyContainer
  }
};

export default useCopy;
