import { useStore } from "vuex";

const useShowData = () => {
  const store = useStore();
  const getDataUser = async () => {
    await store.dispatch("user/getDataUserShow");
  };
	
  return {
    getDataUser,
  };
};

export default useShowData;
