<template>
  <div class="form-wrapper">
    <div class="form form-col-1 mt-2 mb-2">
      <div class="form-content text-center">
        <div class="header-images">
          <div class="logo">
            <img
              src="/images/logos/logo-faber.png"
              alt="Faber Castell"
              title="Faber Castell"
            />
          </div>
          <div class="featured">
            <img
              src="/images/decorations/email-confirmated.png"
              alt="Successful Email"
              title="Successful Email"
            />
          </div>
        </div>
        <div class="body">
          <h1 class="mb-2 font_helvetica_neue75_bold">
            {{ $t('welcome.title') }}
          </h1>
          <!-- <p class="font_helvetica_neue55_roman">
            Want to start the journey?
          </p> -->
        </div>
        <div class="buttons mt-1 mb-1">
          <button @click="$router.push({ path: '/user' })" class="btn-secondary font_helvetica_neue75_bold w-auto">
            {{ $t('welcome.startButton') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
