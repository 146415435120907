<template>
  <div>
    <form class="main-content-profile" @submit.prevent="userFormUpdate">
      <div class="row gap-3 mb-gap justify-between mt-1">
        <div class="col-12">
          <div class="form-control max-w-100 w-100 font_helvetica_neue55_roman">
            <label for="company_name">{{ $t('user.account.labels.companyName') }}:</label>
            <input
              readonly
              type="text"
              class="w-100 font_helvetica_neue55_roman"
              name="company_name"
              placeholder=""
              v-model="user.userData.company_name"
              required
            />
          </div>
        </div>
        <div class="col-12">
          <div
            class="form-control form-control-custom max-w-100 w-100 font_helvetica_neue55_roman"
          >
            <label for="company_email">{{ $t('user.account.labels.companyEmail') }}:</label>
            <div class="input-w-icon input-form">
              <input
                :readonly="setForm"
                type="email"
                class="edit w-100 font_helvetica_neue55_roman"
                name="email"
                v-model="user.userData.email"
                placeholder=""
                required
              />
              <i
                class="fas fa-pencil-alt icon-input"
                :class="setForm ? 'd-none' : ''"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row gap-3 mt-1 mb-gap justify-between ">
        <div class="col-12 col-md-6">
          <div
            class="form-control p-relative max-w-100 w-100 mb-1 font_helvetica_neue55_roman"
          >
            <label for="company_name">{{ $t('user.account.labels.apoderadoName') }}</label>
            <input
              readonly="setForm"
              type="text"
              class="w-100 font_helvetica_neue55_roman"
              v-model="user.userData.name"
              name="name"
              placeholder=""
              required
            />
          </div>
          <div
            class="form-control max-w-100 w-100 font_helvetica_neue55_roman mt-1"
          >
            <label for="company_ruc">{{ $t('user.account.labels.ruc') }}:</label>
            <input
              readonly
              type="number"
              class="w-100 font_helvetica_neue55_roman"
              name="company_ruc"
              placeholder=""
              v-model="user.userData.ruc"
              required
            />
          </div>
          <div
            class="form-control mt-1 form-control-custom max-w-100 w-100 mt-1 font_helvetica_neue55_roman"
          >
            <label for="company_tel">{{ $t('user.account.labels.celular') }}:</label>
            <div class="input-w-icon input-form">
              <input
                :readonly="setForm"
                type="tel"
                class="edit w-100 font_helvetica_neue55_roman"
                name="cellphone"
                placeholder=""
                v-model="userFormUpdate.cellphone"
              />
              <i
                class="fas fa-pencil-alt icon-input"
                :class="setForm ? 'd-none' : ''"
              ></i>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div
            class="form-control form-control-custom max-w-100 w-100 mt-1 direction font_helvetica_neue55_roman"
          >
            <label for="company_address">{{ $t('user.account.labels.direccionFiscal') }}:</label>
            <div class="input-w-icon input-form">
              <textarea
                :readonly="setForm"
                class="edit w-100 font_helvetica_neue55_roman"
                name="direction"
                v-model="userFormUpdate.address"
                rows="6"
              ></textarea>
              <i
                class="fas fa-pencil-alt text-area icon-input"
                :class="setForm ? 'd-none' : ''"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <LoadingComponent v-if="isLoading" />

      <MessageComponent
        v-if="!isLoading && messageForm.message"
        :message="messageForm.message"
        :messageType="messageForm.messageType"
        :classes="['text-center', 'mt-1', 'form-message']"
      />
      <div class="buttons mt-2 text-center d-flex gap-1-5 justify-center">
        <button
          type="button"
          @click="
            selectState === 'view'
              ? setSelectState('edit')
              : setSelectState('view', 'update');
              gtmEditData();
          "
          class="btn btn-secondary font_helvetica_neue75_bold btn-edit-profiled"
        >
          {{ selectState === 'view' ? $t('user.account.buttons.modifyData') : $t('user.account.buttons.updateData') }}
        </button>
        <button
          @click="
            selectState === 'edit' ? setSelectState('view', 'cancel') : logout(); gtmEditData()
          "
          type="button"
          class="btn btn-gold font_helvetica_neue75_bold"
          v-if="selectState !== 'view'"
        >
          {{ selectState === 'view' ? $t('user.account.buttons.closeSession') : $t('user.account.buttons.cancel') }}
        </button>
      </div>
    </form>
  </div>
</template>


<script>
import useProfile from '@/modules/user/composables/Dashboard/useProfile';

import MessageComponent from '@/components/MessageComponent';
import { LoadingComponent } from '@/components';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import gaFunction from '@/utils/ga/gaFunction';

export default {
  components: {
    LoadingComponent,
    MessageComponent,
  },
  methods:{
    gtmEditData(){
      if(this.selectState === 'edit'){
      gaFunction('event', 'clic', { 'event_category' : 'Otras consultas', 'event_label' : 'Editar informacion de mi cuenta'});
      }
      if(this.selectState === 'view'){
        gaFunction('event', 'clic', { 'event_category' : 'Otras consultas', 'event_label' : 'Ver informacion de mi cuenta'})
      }
      if(this.selectState === 'cancel'){
        gaFunction('event', 'clic', { 'event_category' : 'Otras consultas', 'event_label' : 'Actualizar informacion de mi cuenta'});
      }
    }
  },
  setup() {
    const store = useStore();
    const { push } = useRouter();

    const logout = () => {
      store.commit('auth/logout');
      push('/');
    };

    return {
      ...useProfile(),
      ...store.state,
      logout,
    };
  },
};
</script>
