<template>
    <div class="container empty-report">
        <div class="row d-flex justify-content-center text-center">
        <div class="w-100">
            <img
            class="w-75 col-md-6 text-center"
            src="/images/decorations/empty.png"
            alt=""
            />
            <p class="semibold text-gray-500 py-1">
            No cuentas con reportes por el momento
            </p>
        </div>
        </div>
    </div>
</template>
